import chunk from "lodash/chunk"
import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import Caret from "../Symbols/Caret"
import "./Grid.css"
import GridChunk from "./GridChunk"

const Grid = ({
  profiles = [],
  isSoundEnabled,
  setSoundEnabled,
  isLoadBackgrounds,
}) => {
  const [currentChunk, setCurrentChunk] = useState(0)

  // These media queries are aligned with the css break points
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1800px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })

  const chunks = isLargeScreen ? 9 : isMobile ? 1 : 4
  const chunkWidth = isLargeScreen ? 3 : isMobile ? 1 : 2
  const profileChunks = chunk(profiles, chunks)

  return (
    <div className="outer-grid" dir="ltr">
      <div className="scroll">
        <div className="caret">
          <Caret color="var(--clr-light-grey)" animate />
        </div>
        <span className="label">Scroll</span>
      </div>
      {profileChunks.map((profileChunk, chunkIdx) => {
        return (
          <GridChunk
            key={`profileChunk-${chunkIdx}`}
            profileChunk={profileChunk}
            isSoundEnabled={isSoundEnabled}
            isLoadBackgrounds={isLoadBackgrounds}
            setCurrentChunk={() => {
              setCurrentChunk(chunkIdx)
            }}
            setSoundEnabled={setSoundEnabled}
            isLast={chunkIdx === profileChunks.length - 1}
            profiles={profiles}
          />
        )
      })}
      <div
        className="chunk-thumbnails"
        style={{
          width: isLargeScreen ? "18px" : "3px",
          height: isLargeScreen ? "60px" : "150px",
        }}
      >
        {profileChunks.map((p, idx) => {
          return (
            <div
              className="chunk-single-thumbnail"
              key={`profileChunk-thumbnail-${idx}`}
              style={{
                gridTemplateColumns: `repeat(${chunkWidth}, 1fr)`,
              }}
            >
              {profileChunks[0].map((_, jdx) => {
                return (
                  <div
                    key={`profileChunk-thumbnail-${idx}-cell-${jdx}`}
                    style={{
                      backgroundColor:
                        currentChunk === idx
                          ? "var(--clr-primary)"
                          : "var(--clr-mid-grey)",
                      transition: "background-color 1.2s",
                      borderRadius: "10px",
                    }}
                  ></div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Grid

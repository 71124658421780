import DOMPurify from "dompurify"
import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { getNameUri } from "../../utils/index.js"
import { useInterval } from "../../utils/useInterval"
import useInViewAudio from "../../utils/useInViewAudio.js"
import "./GridCell.css"
import GridCellBackground from "./GridCellBackground"
import GridCellNameTag from "./GridCellNameTag"
import GridCellQuote from "./GridCellQuote"

const GridCellMobile = ({
  image,
  quote,
  fullName,
  color,
  video_link,
  videosBackground,
  isLoadBackgrounds,
  isSoundEnabled,
  setSoundEnabled,
}) => {
  const [soundVolume, setSoundVolume] = useState(0)
  const videoRef = useRef()
  const audioRef = useRef()
  const [isEnded, setEnded] = useState(false)
  const [inViewAudio, setInViewAudio] = useState(false)

  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  const profileUri = getNameUri(fullName)

  useEffect(() => {
    if (inView && document.querySelector(".header-banner.fadeout")) {
      videoRef.current?.load()
      //videoRef.current?.play()
    }
  }, [inView, document.querySelector(".header-banner.fadeout")])

  useEffect(() => {
    const doEnd = () => {
      setEnded(true)
    }

    if (inView && isLoadBackgrounds) {
      const vid = videoRef?.current

      setEnded(false)
      if (vid) {
        vid.addEventListener("ended", doEnd, false)
      }
    }

    return () => {
      if (videoRef.current) {
        const vid = videoRef.current
        vid.removeEventListener("ended", doEnd, false)
      }
    }
  }, [inView, isLoadBackgrounds])

  useInterval(
    () => {
      if (inViewAudio) {
        if (soundVolume + 0.083 < 1) {
          setSoundVolume(soundVolume + 0.083)
        } else {
          setSoundVolume(1)
        }
      }
    },
    83,
    [inViewAudio]
  )

  useInterval(
    () => {
      if (!inViewAudio) {
        if (soundVolume - 0.083 > 0) {
          setSoundVolume(soundVolume - 0.083)
        } else {
          setSoundVolume(0)
        }
      }
    },
    83,
    [inViewAudio]
  )

  /*
  useEffect(() => {
    const vid = videoRef?.current

    if (vid) {
      if (isSoundEnabled) {
        vid.volume = soundVolume
        vid.muted = false
      } else {
        vid.volume = 0
        vid.muted = true
      }
    }
  }, [soundVolume, isSoundEnabled])
  */

  const isInViewAudio = useInViewAudio(inView, audioRef?.current)
  useEffect(() => {
    setInViewAudio(isInViewAudio)
  }, [isInViewAudio])

  if (!image) {
    return null
  }

  let translateX = 0
  let translateXvideo = 0
  let style = null

  // Hacky adjustments to center specific video backgrounds
  if (fullName === "Arthur Carter") translateXvideo = 120
  if (fullName === "Jimmy Robinson") translateXvideo = 150
  if (fullName === "Terry Pierce") translateXvideo = 90
  if (fullName === "Alvin Catchings") translateXvideo = -150
  if (fullName === "Vashon Kelly") translateXvideo = 90
  if (fullName === "Jeffrey Hilburn") translateXvideo = 150

  // This for images
  if (fullName === "Jeffrey Nelson") translateX = "-60%"
  if (fullName === "Walter Reed") translateX = "-200px"
  if (fullName === "Arthur Carter") translateX = "-25%"
  if (fullName === "Kuantay Reeder") translateX = "-45%"
  if (fullName === "Vashon Kelly") translateX = "-25%"
  if (fullName === "Terry Pierce") translateX = "-28%"
  if (fullName === "Alvin Catchings") translateX = "-35%"
  if (fullName === "Theortric Givens") translateX = "-30%"
  if (fullName === "Patrick Lucien") translateX = "-25%"
  if (fullName === "Raymond Flank") translateX = "-25%"
  if (fullName === "Jeffrey Hilburn") translateX = "-380px"
  if (fullName === "Lawson Strickland") translateX = "-30%"
  if (fullName === "Jimmy Robinson") translateX = "-350px"
  if (fullName === "Jerome Derricks") translateX = "-30%"

  if (translateX)
    style = {
      transform: `translateX(${translateX})`,
      height: "100%",
      width: "auto",
      right: "auto",
    }

  return (
    <div className={`grid-cell ${inView && "hovered"}`} ref={audioRef}>
      {false && isLoadBackgrounds && (
        <div
          //ref={ref}
          className={`responsive-iframe-container ${
            inView && !isEnded ? "visible" : ""
          } ${isEnded ? "fadeout" : ""}`}
        >
          <video
            ref={videoRef}
            src={`${DOMPurify.sanitize(videosBackground[fullName])}`}
            style={{
              height: "100%",
              transform: `translateX(${translateXvideo}px)`,
            }}
            preload="metadata"
            playsInline
            muted
          ></video>
        </div>
      )}
      {image && isLoadBackgrounds && (
        <GridCellBackground
          isHover={false}
          image={image}
          imgStyle={style}
          fullName={fullName}
        />
      )}
      <div className="cell-hover-layer" ref={ref}></div>
      {quote && (
        <GridCellQuote
          video_link={video_link}
          profileUri={profileUri}
          color={color}
          quote={quote}
        />
      )}
      <GridCellNameTag
        video_link={video_link}
        profileUri={profileUri}
        isHover={inView}
        fullName={fullName}
        isSoundEnabled={
          true /* so tht the button doesn't show up; old was isSoundEnabled */
        }
        setSoundEnabled={() => {} /*setSoundEnabled*/}
        setSoundVolume={() => {} /*setSoundVolume*/}
        color={color}
      />
    </div>
  )
}

export default GridCellMobile

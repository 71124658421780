import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import VisitingRoom from "../components/VisitingRoom/VisitingRoom"
import useClickDetector from "../utils/useClickDetector"
import useProfiles from "../utils/useProfiles"
import HeaderSEO from "../components/Header/HeaderSEO"

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const VisitingRoomPage = (props) => {
  useClickDetector()
  const { loading, profiles, images } = useProfiles(props)
  const [displayedProfiles, setDisplayedProfiles] = useState(profiles)

  useEffect(() => {
    setDisplayedProfiles(
      shuffleArray(
        Object.values(profiles).filter((p) => p.show_profile_in_visiting_room)
      )
    )
  }, [profiles])

  return (
    <VisitingRoom
      loading={loading}
      profiles={displayedProfiles}
      images={images}
    />
  )
}

export const query = graphql`
  query getProfilesVR {
    allPrismicProfile(
      filter: { data: { show_profile_in_visiting_room: { eq: true } } }
    ) {
      edges {
        node {
          data {
            first_name {
              text
            }
            date_of_birth {
              text
            }
            time_served {
              text
            }
            age_at_interview {
              text
            }
            last_name {
              text
            }
            full_name {
              text
            }
            imagepath {
              text
            }
            quote {
              text
            }
            deceased_date
            show_profile_in_visiting_room
            color
            profile_picture {
              gatsbyImageData
            }
            video_link {
              url
            }
          }
        }
      }
    }
  }
`

export const Head = () => <HeaderSEO />

export default VisitingRoomPage

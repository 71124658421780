import DOMPurify from "dompurify"
import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { getNameUri } from "../../utils/index.js"
import { useInterval } from "../../utils/useInterval"
import "./GridCell.css"
import GridCellBackground from "./GridCellBackground"
import GridCellNameTag from "./GridCellNameTag"
import GridCellQuote from "./GridCellQuote"

const GridCellDesktop = ({
  image,
  quote,
  fullName,
  color,
  video_link,
  isSoundEnabled,
  setSoundEnabled,
  videosBackground,
  isLoadBackgrounds,
}) => {
  const videoRef = useRef()
  const [isHover, setHover] = useState(false)
  const [isFadingin, setFadingin] = useState(false)
  const [isFadingout, setFadingout] = useState(false)
  const [isEnded, setEnded] = useState(false)
  const [isFailed, setFailed] = useState(false)
  const [soundVolume, setSoundVolume] = useState(0)
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const profileUri = getNameUri(fullName)

  useEffect(() => {
    if (inView) {
      videoRef.current?.load()
    }
  }, [inView])

  useEffect(() => {
    let timeout
    if (isHover) {
      clearTimeout(timeout)
      if (!inView) {
        setHover(false)
      }
      videoRef.current?.play()
    } else {
      const vid = videoRef.current
      if (vid) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          vid.pause()
          vid.currentTime = 0
        }, 1200)
      }
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isHover, inView])

  useEffect(() => {
    const doEnd = () => {
      setEnded(true)
    }

    if (isHover && inView && isLoadBackgrounds) {
      const vid = videoRef?.current

      setEnded(false)
      if (vid) {
        vid.addEventListener("ended", doEnd, false)
      }
    }

    return () => {
      if (videoRef.current) {
        const vid = videoRef.current
        vid.removeEventListener("ended", doEnd, false)
      }
    }
  }, [isHover, inView, isLoadBackgrounds])

  useInterval(
    () => {
      if (isFadingin) {
        if (soundVolume + 0.083 < 1) {
          setSoundVolume(soundVolume + 0.083)
        } else {
          setSoundVolume(1)
        }
      }
    },
    83,
    [isFadingin]
  )

  useInterval(
    () => {
      if (isFadingout) {
        if (soundVolume - 0.083 > 0) {
          setSoundVolume(soundVolume - 0.083)
        } else {
          setSoundVolume(0)
        }
      }
    },
    83,
    [isFadingout]
  )

  useEffect(() => {
    const vid = videoRef?.current

    if (vid) {
      if (isSoundEnabled) {
        vid.volume = soundVolume
        vid.muted = false
      } else {
        vid.volume = 0
        vid.muted = true
      }
    }
  }, [soundVolume, isSoundEnabled])

  if (!image) {
    return null
  }

  return (
    <div
      className={`grid-cell fadeinfast ${isHover ? "hovered" : ""}`}
      ref={ref}
      onMouseEnter={() => {
        setHover(true)
        setFadingin(true)
        setFadingout(false)
      }}
      onMouseMove={() => {
        setHover(true)
        setFadingin(true)
        setFadingout(false)
      }}
      onMouseLeave={() => {
        setFadingin(false)
        setFadingout(true)
        setHover(false)
      }}
    >
      {isFailed && isHover && (
        <div className="cell-hover-preview light fadeinfast">
          <button
            className="secondary"
            onClick={() => {
              setFailed(false)
            }}
          >
            Video not available
          </button>
        </div>
      )}
      {image && isLoadBackgrounds && (
        <GridCellBackground
          isHover={isHover && !isFailed}
          image={image}
          fullName={fullName}
        />
      )}
      {isLoadBackgrounds && (
        <div
          className={`responsive-iframe-container ${
            isHover && !isFailed && !isEnded && "visible"
          }
          ${isEnded ? "fadeout" : ""}
          
          `}
        >
          <video
            ref={videoRef}
            id={`video-${DOMPurify.sanitize(profileUri)}`}
            playsInline
            src={`${DOMPurify.sanitize(videosBackground[fullName])}`}
            autoPlay={false}
            preload="none"
            muted
          ></video>
        </div>
      )}
      <div className="cell-hover-layer"></div>
      {quote && (
        <GridCellQuote
          video_link={video_link}
          profileUri={profileUri}
          color={color}
          quote={quote}
        />
      )}
      <GridCellNameTag
        video_link={video_link}
        profileUri={profileUri}
        isHover={true}
        fullName={fullName}
        isSoundEnabled={
          true /* so tht the button doesn't show up; old was isSoundEnabled */
        }
        setSoundEnabled={() => {} /*setSoundEnabled*/}
        setSoundVolume={() => {} /*setSoundVolume*/}
        soundVolume={soundVolume}
        color={color}
      />
    </div>
  )
}

export default GridCellDesktop

import React from "react"
import MenuButton from "../Menu/MenuButton"
import IconSound from "../Symbols/Sound"
import "./GridCellSoundButton.css"
import { getColor } from "./utils"

const GridCellSoundButton = ({
  color,
  isHover,
  isSoundEnabled,
  setSoundEnabled,
}) => {
  return (
    <div
      className={`menu-buttons grid-cell-sound-button ${
        true && !isSoundEnabled && "fadein"
      }`}
    >
      <MenuButton
        onTouchStart={(e) => {
          e.stopPropagation()
          setSoundEnabled(true)
        }}
        onMouseDown={(e) => {
          e.stopPropagation()
          setSoundEnabled(true)
        }}
        onMouseUp={(e) => {
          e.stopPropagation()
          setSoundEnabled(false)
        }}
        onTouchEnd={(e) => {
          e.stopPropagation()
          setSoundEnabled(false)
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        buttonContent={<IconSound />}
        tooltipContent={"Hold to listen"}
        styling={{
          flexDirection: "row",
          width: "150px",
          justifyContent: "center",
        }}
        tooltipStyling={{
          background: `var(--${getColor(color)})`,
          fontSize: "var(--font-small)",
          marginTop: "0",
          marginLeft: "8px",
          marginRight: "auto",
        }}
      />
    </div>
  )
}
export default GridCellSoundButton

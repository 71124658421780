export const videosBackground = {
  "Alvin Catchings":
    "https://prismic-io.s3.amazonaws.com/visiting-room/03336442-9dcb-4416-ac11-49eb8a36f26d_AlvinCatchings_Caregiver.mp4",
  "Arthur Carter":
    "https://prismic-io.s3.amazonaws.com/visiting-room/2e63dec6-a14a-4a7f-8aee-0d37bbc9a48b_ArthurCarter_Considered.mp4",
  "Archie Tyner":
    "https://prismic-io.s3.amazonaws.com/visiting-room/52cb38d0-4bd6-4b8b-b17f-8d67671d1290_ArchieTyner_Twins.mp4",
  "Anthony Hingle":
    "https://prismic-io.s3.amazonaws.com/visiting-room/f5902365-0675-4a2e-875e-92f23210ed06_AnthonyHingle_Barbershop_H264.mp4",
  "Darnell Craft":
    "https://prismic-io.s3.amazonaws.com/visiting-room/a1936dbe-dddf-4208-87e7-b68fe1d76dd8_DarnellCraft_Teenager_2.mp4",
  "Darwin Willie":
    "https://prismic-io.s3.amazonaws.com/visiting-room/b82ff0d4-dc62-49f0-a792-2cbe1f78c140_DarwinWillie_Mercy2.mp4",
  "Daryl Waters":
    "https://prismic-io.s3.amazonaws.com/visiting-room/e340eeff-2b8f-4fbc-a939-77523c8f67f5_DarylWaters_Grandfather.mp4",
  "David Chenevert":
    "https://prismic-io.s3.amazonaws.com/visiting-room/6274d268-4bcf-4726-bcd9-95c3b25310fb_DavidChenevert_Law2.mp4",
  "Donahue Smith":
    "https://prismic-io.s3.amazonaws.com/visiting-room/4e14284c-1dd2-4487-982e-5691ad0ec174_DonahueSmith_Wall.mp4",
  "Edbert Simmons":
    "https://prismic-io.s3.amazonaws.com/visiting-room/7f5e3fec-dac4-4812-b6d1-81edd1f1e6ef_EdbertSimmons_Pray.mp4",
  "Frank Green":
    "https://prismic-io.s3.amazonaws.com/visiting-room/41b49c9b-37df-43c9-8d35-d966eec5f7f3_FrankGreen_FamilyMen2.mp4",
  "Gordon Newman":
    "https://prismic-io.s3.amazonaws.com/visiting-room/d090cb7d-7750-429c-bb3b-93522f8e4d16_GordonNewman_Plantation2.mp4",
  "Hannibal Stanfield":
    "https://prismic-io.s3.amazonaws.com/visiting-room/6c73f222-1a13-4d5e-9cce-aba4543d85e5_HannibalStanfield_SlowDeath.mp4",
  "Hayward Jones":
    "https://prismic-io.s3.amazonaws.com/visiting-room/3886eb87-66e7-40b1-b345-9d6b05c41c34_HaywardJones_Extraordinary2.mp4",
  "Bernell Juluke":
    "https://prismic-io.s3.amazonaws.com/visiting-room/3d981feb-9b4f-4d0d-a971-52247d31d083_BernellJuluke_Getout_2.mp4",
  "Jack Segura":
    "https://prismic-io.s3.amazonaws.com/visiting-room/a59d6eae-6344-4a6b-bdac-12169bc4dcde_JackSegura_Hospital.mp4",
  "Jarrod Lanclow":
    "https://prismic-io.s3.amazonaws.com/visiting-room/9b791686-f20d-4a16-b290-b2c258026717_JarredLanclow_Home.mp4",
  "Jeffrey Hilburn":
    "https://prismic-io.s3.amazonaws.com/visiting-room/67214fd8-2160-4404-9dbf-218e549da0cb_JeffreyHillburn_AtAngola.mp4",
  "Jimmy Robinson":
    "https://prismic-io.s3.amazonaws.com/visiting-room/21856246-df21-40eb-b178-66716606a9e8_JimmyRobinson_Lord.mp4",
  "Jeffrey Nelson":
    "https://prismic-io.s3.amazonaws.com/visiting-room/aa76eb7c-ba02-4e15-a44a-cb1cc9e3e27a_JeffreyNelson_human2.mp4",
  "Jerome Derricks":
    "https://prismic-io.s3.amazonaws.com/visiting-room/8480f19a-781e-487d-946a-c781e94274bd_JeromeDerricks_Selfworth_2_extended.mp4",
  "Kenneth Woodburn":
    "https://prismic-io.s3.amazonaws.com/visiting-room/56b74c9f-33ed-4bfd-8d31-b2d397d9f3d0_KennethWoodburn_RightThing.mp4",
  "Kuantay Reeder":
    "https://prismic-io.s3.amazonaws.com/visiting-room/a86289e6-4039-4355-a67c-5fb7962205c9_KauntauReeder_Flowers_3.mp4",
  "Kendrick Fisher":
    "https://prismic-io.s3.amazonaws.com/visiting-room/c374a7bf-5be9-42bf-a519-2c62001d8cd3_KendrickFisher_7Years_2.mp4",
  "Lawson Strickland":
    "https://prismic-io.s3.amazonaws.com/visiting-room/b05aa52c-b4b1-400e-9207-16e930748df4_TheVisitingRoom_LawsonStrickland_UHD_24p_h264_WebMixStereo.mp4",
  "Patrick Johnson":
    "https://prismic-io.s3.amazonaws.com/visiting-room/7638cc0d-70bc-4da0-a952-25a34f1afea1_TheVisitingRoom_PatrickJohnson_UHD_24p_ProRes422HQ_WebMixStereo.mp4",
  "Patrick Lucien":
    "https://prismic-io.s3.amazonaws.com/visiting-room/aeafd4d5-bd4a-4cbf-a7a0-78fb37913c1e_TheVisitingRoom_PatrickLucien_UHD_24p_h264_WebMixStereo.mp4",
  "Raymond Flank":
    "https://prismic-io.s3.amazonaws.com/visiting-room/23a07fea-3d7b-447d-a5bf-260dfcd7636f_TheVisitingRoom_RaymondFlank_UHD_24p_ProRes422HQ_WebMixStereo.mp4",
  "Sammie Robinson":
    "https://prismic-io.s3.amazonaws.com/visiting-room/e87de13f-e059-47cc-ad96-64519759e4c8_TheVisitingRoom_SammieRobinson_UHD_24p_ProRes422HQ_WebMixStereo.mp4",
  "Terrence Guy":
    "https://prismic-io.s3.amazonaws.com/visiting-room/22ceec91-3fba-433e-a428-f9a5c13b4801_TheVisitingRoom_TerrenceGuy_UHD_24p_ProRes422HQ_WebMixStereo.mp4",
  "Terry Pierce":
    "https://prismic-io.s3.amazonaws.com/visiting-room/42a720af-e8db-4d93-9145-59c97cfc5911_TerryPierce_Yard.mp4",
  "Terry West":
    "https://prismic-io.s3.amazonaws.com/visiting-room/fdd68bfe-e6c5-4fa1-bb10-289375ce5b74_TerryWest_WhereI%27mfrom.mp4",
  "Theortric Givens":
    "https://prismic-io.s3.amazonaws.com/visiting-room/ae5e2ead-eed5-4178-a351-99c255aba4f9_TheortricGivens_Askyourself.mp4",
  "Vashon Kelly":
    "https://prismic-io.s3.amazonaws.com/visiting-room/44756852-8f98-47dc-bedf-ef506373b051_VashonKelly_Family.mp4",
  "Walter Goodwin":
    "https://prismic-io.s3.amazonaws.com/visiting-room/7724c811-5b5f-4731-bc4f-f8be0d828aa8_WalterGoodwin_Katrina.mp4",
  "Walter Reed":
    "https://prismic-io.s3.amazonaws.com/visiting-room/3348e7aa-9d17-4e2a-a94b-7d298d908710_TheVisitingRoom_WalterReed_UHD_24p_h264_WebMixStereo.mp4",
}

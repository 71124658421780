import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const GridCellBackground = ({
  isHover,
  image,
  oldImage,
  imgStyle,
  fullName,
}) => {
  return (
    <div
      className={`cell-background ${!isHover ? "fadein" : ""} ${
        isHover ? "fadeoutslow" : ""
      }`}
    >
      <GatsbyImage
        alt={`Picture of ${fullName}`}
        image={image}
        imgStyle={{
          objectFit: "cover",
          ...imgStyle,
        }}
      />
      {oldImage && (
        <GatsbyImage
          alt={`Picture of ${fullName}`}
          image={oldImage.node.childImageSharp.gatsbyImageData}
          imgStyle={{
            objectFit: "cover",
          }}
        />
      )}
    </div>
  )
}

export default GridCellBackground

import React from "react"
import { videosBackground } from "./constants"
import "./GridCell.css"
import GridCellDesktop from "./GridCellDesktop"
import GridCellMobile from "./GridCellMobile"

const GridCell = ({
  image,
  quote,
  fullName,
  setSoundEnabled,
  color,
  isSoundEnabled,
  video_link,
  isLoadBackgrounds,
}) => {
  return (
    <>
      <div className="grid-cell-mobile">
        <GridCellMobile
          image={image}
          quote={quote}
          fullName={fullName}
          color={color}
          video_link={video_link}
          videosBackground={videosBackground}
          isSoundEnabled={isSoundEnabled}
          setSoundEnabled={setSoundEnabled}
          isLoadBackgrounds={isLoadBackgrounds}
        />
      </div>
      <div className="grid-cell-desktop">
        <GridCellDesktop
          image={image}
          quote={quote}
          fullName={fullName}
          color={color}
          setSoundEnabled={setSoundEnabled}
          video_link={video_link}
          isSoundEnabled={isSoundEnabled}
          videosBackground={videosBackground}
          isLoadBackgrounds={isLoadBackgrounds}
        />
      </div>
    </>
  )
}

export default GridCell

import { useEffect, useState } from "react"

function useInViewAudio(inView, element) {
  const [isInViewAudio, setInViewAudio] = useState(false)

  useEffect(() => {
    let observer
    if (inView) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio > 0.5) {
            setInViewAudio(true)
          } else {
            setInViewAudio(false)
          }
        },
        { root: null, rootMargin: "0px", threshold: 0.5 }
      )

      if (element) observer.observe(element)
    } else {
      if (observer) observer.disconnect()
    }

    return () => {
      if (observer) observer.disconnect()
    }
  }, [inView])

  return isInViewAudio
}

export default useInViewAudio
